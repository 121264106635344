.ab-home-bodega {
  padding: 125px Max(113px, calc((100% - 1440px) / 2 + 113px));
  position: relative;
  background-color: #f9f6ef;
  box-sizing: border-box;
  height: 860px;
  flex-direction: row;

  @media screen and (max-width: 1200px) {
    padding: 71px 0;
    background-position: bottom center;
    background-size: initial;
    background-size: 200% auto;
    flex-direction: column;
    height: initial;
    align-items: center;
  }

  &.white {
    background-color: #fff;
  }

  &.left {
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (max-width: 1200px) {
      padding-top: 71px;
      padding-bottom: 71px;

      & > .details {
        align-items: flex-start;

        & > p {
          text-align: left;
        }

        & > .ab-link {
          align-self: left;
        }
      }
    }

    & > .details {
      align-items: flex-start;

      & > p {
        text-align: left;
      }

      & > .ab-link {
        align-self: left;
      }
    }

    & > div {
      background-size: auto 798px;

      & > div {
        align-items: flex-start;

        h2 {
          text-align: left;
        }

        & > p {
          text-align: left;
        }

        & > .ab-link {
          text-align: left;
        }
      }
    }

    & > hr {
      border-bottom: none;
    }
  }

  & > .details {
    padding-top: 125px;
    padding-bottom: 125px;
    width: 50%;
    align-items: center;
    max-width: 280px;

    @media screen and (max-width: 1200px) {
      padding: 0 30px;
      width: 100%;

      box-sizing: border-box;

      & > div {
        width: 100% !important;
        align-items: center !important;

        & > h2 {
          width: 100%;
          text-align: center !important;
        }
      }
    }

    & > span {
      background: #fffefc;
      margin-bottom: 22px;
    }

    & > div {
      width: 50%;

      & > h2 {
        text-align: center;
      }
    }

    & > p {
      margin-top: 42px;
      text-align: left;
      margin-bottom: 22px;
      color: #8c8181;
      font-family: GT Eesti;
      width: 100%;
      max-width: 298px;
      text-align: center;

      @media screen and (max-width: 1200px) {
        max-width: initial;
      }
    }

    & > a {
      &:not(.ab-link) {
        text-align: center;
        align-self: flex-start;
      }
    }
  }

  & > .images {
    align-self: center;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
    transition: all 3500ms ease !important;

    &:hover,
    &.in-viewport {
      & > :first-child {
        transform: translateX(-130px);
        opacity: 1;
      }

      & > :nth-child(2) {
        transform: translateX(130px);
        opacity: 1;
      }
    }

    & > picture,
    & > div {
      transition: all 3500ms ease !important;
      position: absolute;
      bottom: 125px;
      width: auto;
      max-height: 626px;

      & > span {
        & > img {
          width: auto;
          max-height: 626px;
          height: auto;

          @media screen and (max-width: 1200px) {
            width: 100%;
          }
        }
      }
    }

    & > :first-child {
      bottom: 140px;
      opacity: 0;
      transform: translateX(0px);

      & > img {
        max-height: 600px;
      }
    }

    & > :nth-child(2) {
      max-height: 600px;
      bottom: 140px;
      opacity: 0;
      transform: translateX(0px);

      & > img {
        max-height: 600px;
      }
    }

    @media screen and (max-width: 1200px) {
      position: relative;
      flex-direction: row;
      justify-content: center;
      margin-top: 25px;

      & > picture,
      & > div {
        transition: all 3000ms ease !important;
        transition-delay: 1000ms;
        position: relative;
        max-width: 100%;
        max-width: 96px;
        bottom: 0px;
        height: auto;
      }

      & > :nth-child(1) {
        bottom: 10px;
        max-width: 90px;
        transform: translateX(90px);
        opacity: 0;
      }

      & > :nth-child(3) {
        bottom: 0px;
        width: 96px;
        transform: translateX(-90px);
        opacity: 1;
      }

      & > :nth-child(2) {
        bottom: 10px;
        max-width: 90px;
        transform: translateX(0px);
        opacity: 0;
      }

      &:hover,
      &.in-viewport {
        & > :nth-child(1) {
          bottom: 5px;
          max-width: 90px;
          transform: translateX(10px);
          opacity: 1;
        }

        & > :nth-child(3) {
          bottom: 0px;
          width: 96px;
          transform: translateX(-90px);
          opacity: 1;
        }

        & > :nth-child(2) {
          bottom: 5px;
          max-width: 90px;
          transform: translateX(90px);
          opacity: 1;
        }
      }
    }
  }

  & > .right {
    padding-top: 125px;
    padding-bottom: 125px;
    width: 50%;
    max-width: 280px;
    align-items: center;

    & > :first-child {
      color: #8c8181;
      font-family: GT Eesti;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.01em;
    }

    & > .row {
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 45px;

      & > .link {
        margin-bottom: 25px;
        margin-right: 30px;
      }
    }

    & > .web {
      display: flex !important;
    }

    & > .mobile {
      display: none !important;
    }

    @media screen and (max-width: 1200px) {
      width: auto;
      max-width: unset !important;

      padding: 25px;

      & > .web {
        display: none !important;
      }

      & > .mobile {
        display: flex !important;
        margin-top: 40px;
      }
    }
  }
}
