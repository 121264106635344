@import '@styles/_variables.scss';
.ab-tag {
  font-family: GT Eesti;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #a79669;
  width: fit-content;
  background: #f9f6ef;
  border-radius: 10.5px;
  padding: 2px 4px;
}
